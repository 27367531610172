export const CODE_OPTIONS = {
  EMAIL: 'email',
  PHONE: 'phoneNumber'
}

export const CODE_MESSAGE = {
  BY_EMAIL: `Si el usuario existe, recibirá un correo electrónico con un código.
            Tu correo puede almacenarlo como spam, revisa tu bandeja en la carpeta spam o correo no deseado.`,
  BY_PHONE: `Si el usuario existe, recibirá un mensaje con un código.`
}

export const TableDefaults = {
  PageSize: 10
}

export const YEAR_RANGE = 100;

export const LABORATORY_FUNCTIONS = {
  createStudy: '1ed727f5-c2d4-632a-a533-92f70d155d1d',
  getMedicineTickets: '1ed727f6-d10f-6db2-a4b8-92f70d155d1d',
  createMedicineTicketBatch: '1ed727f7-8aab-69ba-8be1-92f70d155d1d',
  updateStudyOrders: '1ed727f8-2416-6dc0-88f6-92f70d155d1d',
  getStudyOrders: '1ed727f8-a5bc-6ad2-91e8-92f70d155d1d',
}

export const USER_FUNCTIONS = {
  logout: '1eba6ad0-3df7-62d2-976d-2cf05d694255',
  getDataUser: '1eba6ad0-3e07-6182-b8f3-2cf05d694255',
  getUsers: '1eba6ad0-3e07-65a6-b221-2cf05d694255',
  updateUserById: '1eba6ad0-3e07-683a-b147-2cf05d694255',
  updateUserData: '1eba6ad0-3e07-6a7e-95a2-2cf05d694255',
  deleteUser: '1eba6ad0-3e07-6cae-9b46-2cf05d694255',

  createDoctor: '1eba6ad0-3e07-6eca-8a79-2cf05d694255',
  getDataDoctor: '1eba6ad0-3e08-6208-867e-2cf05d694255',
  getDoctorById: '1eba6ad0-3e08-66fe-bd4b-2cf05d694255',
  getDoctors: '1eba6ad0-3e08-6b68-9f04-2cf05d694255',
  updateDoctorById: '1eba6ad0-3e09-6072-ba70-2cf05d694255',
  updateDoctorData: '1eba6ad0-3e09-6504-8c2a-2cf05d694255',
  deleteDoctor: '1eba6ad0-3e09-69e6-bc39-2cf05d694255',

  createLaboratory: '1eba6ad0-3e09-6d10-939a-2cf05d694255',
  getLaboratoryById: '1eba6ad0-3e09-6f40-bffe-2cf05d694255',
  getLaboratories: '1eba6ad0-3e0a-6152-83be-2cf05d694255',
  updateLaboratoryByAdmin: '1eba6ad0-3e0a-6378-8cf6-2cf05d694255',
  updateLaboratoryById: '1eba6ad0-3e0a-658a-9b93-2cf05d694255',
  deleteLaboratory: '1eba6ad0-3e0a-6792-9371-2cf05d694255',

  createMedicine: '1eba6ad0-3e0a-6990-9571-2cf05d694255',
  getMedicineById: '1eba6ad0-3e0a-6b98-b4c8-2cf05d694255',
  getMedicinesByLaboratory: '1eba6ad0-3e0a-6d96-a98e-2cf05d694255',
  updateMedicineByAdmin: '1eba6ad0-3e0a-6f9e-a39f-2cf05d694255',
  updateMedicineById: '1eba6c0a-e514-698a-ae63-2cf05d694255',
  deleteMedicine: '1eba6ad0-3e0b-61a6-a12b-2cf05d694255',

  createProgram: '1eba7b2a-1cbc-6292-abd9-2cf05d694255',
  getProgramById: '1eba7b2a-1cc3-61a0-aa80-2cf05d694255',
  getProgramsByLaboratory: '1eba7b2a-1cc3-62f4-bf10-2cf05d694255',
  updateProgramById: '1eba7b2a-1cc3-638a-85b6-2cf05d694255',
  deleteProgram: '1eba7b2a-1cc3-640c-b210-2cf05d694255',

  createMedicalRepresentative: '1eba7b2a-1cc3-6484-a426-2cf05d694255',
  getMedicalRepresentativeById: '1eba7b2a-1cc3-64fc-a29c-2cf05d694255',
  getAllMedicalRepresentatives: '1eba9e56-24d0-6cea-a145-2cf05d694255',
  getMedicalRepresentativesByLaboratory: '1eba7b2a-1cc3-6574-9b90-2cf05d694255',
  updateMedicalRepresentativeById: '1eba7b2a-1cc3-65ec-ace6-2cf05d694255',
  deleteMedicalRepresentative: '1eba7b2a-1cc3-6664-9f7e-2cf05d694255',

  createManager: '1eba7b2a-1cc3-66dc-8d1e-2cf05d694255',
  getManagerById: '1eba7b2a-1cc3-674a-8d5a-2cf05d694255',
  getAllManagers: '1eba9e56-2499-60e2-b718-2cf05d694255',
  getManagersByLaboratory: '1eba7b2a-1cc3-67c2-8f45-2cf05d694255',
  updateManagerById: '1eba7b2a-1cc3-683a-832e-2cf05d694255',
  deleteManager: '1eba7b2a-1cc3-68a8-9be1-2cf05d694255',

  createLaboratoryUser: '1eba91b1-eb1f-6236-937c-2cf05d694255',
  getLaboratoryUserById: '1eba91b1-eb39-61e0-adc5-2cf05d694255',
  getDataLaboratoryUser: '1eba91b1-eb39-65b4-8720-2cf05d694255',
  getAllLaboratoryUsers: '1eba91b1-eb39-680c-aeac-2cf05d694255',
  getLaboratoryUsersByLaboratory: '1eba9e56-24d1-60f0-9c08-2cf05d694255',
  updateLaboratoryUser: '1eba91b1-eb39-6a0a-9a4c-2cf05d694255',
  updateLaboratoryUserData: '1eba91b1-eb39-6c08-99ba-2cf05d694255',
  deleteLaboratoryUser: '1eba91b1-eb39-6de8-ba8d-2cf05d694255',

  createMedicalSpeciality: '1ebac384-f56e-664c-a006-2cf05d694255',
  getMedicalSpecialityById: '1ebac384-f573-6430-bac4-2cf05d694255',
  getMedicalSpecialities: '1ebac384-f573-6598-a474-2cf05d694255',
  updateMedicalSpeciality: '1ebac384-f573-6688-827f-2cf05d694255',
  deleteMedicalSpeciality: '1ebac384-f573-675a-a107-2cf05d694255',

  createInitialTreatmentsOrder: '1ebadfd9-6b0c-6faa-a44f-2cf05d694255',
  getInitialTreatmentById: '1ebadfd9-6b18-6134-81cc-2cf05d694255',
  getInitialTreatmentsOrders: '1ebadfd9-6b18-62c4-ab54-2cf05d694255',
  getInitialTreatmentsByDoctor: '1ebadfd9-6b18-63be-ae52-2cf05d694255',
  getInitialTreatmentMedicines: '1ebb4de7-9dc0-602e-b5f1-2cf05d694255',
  updateInitialTreatmentByAdmin: '1ebadfd9-6b18-649a-b3e0-2cf05d694255',
  deleteInitialTreatmentOrder: '1ebadfd9-6b18-6562-bf68-2cf05d694255',

  getSuborder: '1ebadfd9-6b18-6634-bb9b-2cf05d694255',
  getSubordersByOrder: '1ebadfd9-6b18-66fc-bc70-2cf05d694255',
  updateSuborderByAdmin: '1ebadfd9-6b18-67ba-b3cc-2cf05d694255',
  deleteSuborder: '1ebadfd9-6b18-6882-af3a-2cf05d694255',

  createDrugstore: '1ebaec68-814f-68ee-a304-2cf05d694255',
  getDrugstoreById: '1ebaec68-815a-6e42-99c5-2cf05d694255',
  getAllDrugstores: '1ebaec68-815a-6fd2-aec5-2cf05d694255',
  updateDrudstoreByAdmin: '1ebaec68-815b-60c2-a01f-2cf05d694255',
  deleteDrugstore: '1ebaec68-815b-619e-9630-2cf05d694255',

  createDrugstoreSuborder: '1ebaec68-815b-6270-9f8d-2cf05d694255',
  getDrugstoreSuborderById: '1ebaec68-815b-6338-a596-2cf05d694255',
  getDrugstoreSubordersByTreatment: '1ebaec68-815b-6400-b21c-2cf05d694255',
  deleteDrugstoreSuborder: '1ebaec68-815b-64c8-bb39-2cf05d694255',

  markFavoriteMedicine: '1ebb274b-06e9-6da2-803a-2cf05d694255',
  getFavoriteMedicine: '1ebb274b-0703-600e-aaa8-2cf05d694255',
  getFavoritesMedicinesByDoctor: '1ebb274b-0703-655e-a513-2cf05d694255',
  deleteFavoriteMedicine: '1ebb274b-0703-695a-a6ef-2cf05d694255',

  affiliateDoctorToLaboratory: '1ebb33b4-4e92-60f8-b9e6-2cf05d694255',
  getDoctorAffiliation: '1ebb33b4-4e9e-6b28-aeeb-2cf05d694255',
  getDoctorsByLaboratory: '1ebb33b4-4e9e-6fb0-b062-2cf05d694255',
  deleteAffiliation: '1ebb33b4-4e9f-6212-8dd9-2cf05d694255',

  createTherapeuticClass: '1ebb3508-6bf5-653c-9789-2cf05d694255',
  getTherapeuticClass: '1ebb3508-6bff-6e2e-931c-2cf05d694255',
  getTherapeuticClasses: '1ebb3508-6bff-6faa-bf8b-2cf05d694255',
  getTherapeuticClassesByDoctor: '1ebb82ec-b7e2-671a-806f-2cf05d694255',
  updateTherapeuticClass: '1ebb3508-6c00-60a4-a001-2cf05d694255',
  deleteTherapeuticClass: '1ebb3508-6c00-6180-abfa-2cf05d694255',

  createAtcMedicine: '1ebb367f-53ee-6826-bd8e-2cf05d694255',
  getAtcMedicine: '1ebb367f-53ff-62ac-93e1-2cf05d694255',
  getAllAtcMedicines: '1ebb367f-53ff-65ae-88ee-2cf05d694255',
  getMedicinesByAtcCode: '1ebb367f-53ff-678e-af37-2cf05d694255',
  deleteAtcMedicine: '1ebb367f-53ff-693c-b9d0-2cf05d694255',

  createMedicalSample: '1ebb4de7-9db3-6860-997b-2cf05d694255',
  getMedicalSampleById: '1ebb4de7-9dbf-67a0-84e4-2cf05d694255',
  getMedicalSampleForAdmin:  '1ebd3b2f-8857-6426-9037-2cf05d694255',
  getMedicalSampleOrdersForLaboratory: '1ebd5f84-06bf-6544-b485-2cf05d698255',
  getMedicalSampleOrders: '1ebb4de7-9dbf-6b60-b7a0-2cf05d694255',
  getMedicalSamplesByDoctor: '1ebb4de7-9dbf-6dea-bc02-2cf05d694255',
  getMedicalSampleMedicines: '1ebb999f-73f5-61d2-a920-2cf05d694255',
  getTherapeuticClassesMedicalSample: '1ebb9b2f-1d5e-6990-bf6b-2cf05d694255',
  updateMedicalSampleById: '1ebb8d6b-06db-6794-84ec-2cf05d694255',
  deleteMedicalSampleOrder: '1ebb8d6b-06f6-6486-ba86-2cf05d694255',

  assignMedicineToDoctor: '1ebb4de7-9dc0-625e-a747-2cf05d694255',
  getDoctorMedicineQuantity: '1ebd5f84-06de-6446-b485-2cf05d694255',

  createAdminUser: '1ebbca3e-5f56-6f9e-86bf-2cf05d694255',
  getAdminRoles: '1ebbca3e-5f64-6c52-8659-2cf05d694255',
  getDataUserById: '1ebbca3e-5f65-6062-bd6f-2cf05d694255',

  createDoctorByAdmin: '1ebbe595-b1ab-6b92-9adb-2cf05d694255',

  getMedicineForAdmin: '1ebc3bf6-da4d-66d8-9ecf-2cf05d694255',

  createDrugstoreMedicine: '1ebc5915-855c-6610-8ef5-2cf05d694255',
  getDrugstoreMedicineById: '1ebc5915-86a5-6db4-8635-2cf05d694255',
  getDrugstoreMedicinesByMedicine: '1ebc5915-86a6-607a-9865-2cf05d694255',
  getPricesByMedicine: '1ebc5915-86a6-61a6-9325-2cf05d694255',
  updateDrugstoreMedicineByAdmin: '1ebc5915-86a6-6296-8a81-2cf05d694255',
  deleteDrugstoreMedicine: '1ebc5915-86a6-637c-b28b-2cf05d694255',

  getDrugstoresWithoutPrices: '1ebc9512-a614-6e50-8a01-2cf05d694255',

  getLaboratoriesByDoctor: '1ebca191-1cc3-6508-924b-2cf05d694255',
  getInitialTreatmentForAdmin: '1ebcd3e0-600c-6d1a-adb7-2cf05d694255',

  getInitialTreatmentStatuses: '1ebceb84-b1e8-6e52-90e8-2cf05d694255',
  getMedicalSampleStatuses: '1ebceb84-b270-6bd6-b1c4-2cf05d694255',

  getDoctorsUnaffiliated: '1ebd517a-72bc-6164-b964-2cf05d694255',

  getTreatmentMedicinesByDoctor: '1ebd5f84-06df-6546-b485-2cf05d694255',

  getMedicalSampleMedicinesByDoctor: '1ec22eab-4e1f-6bae-ad73-2cf05d694255',

  getMedicineByKeyWord: '1ebc3bf6-da4d-66e9-9ecf-2cf05d694255',

  assignBatchMedicineToDoctorForAdmin: '1ebd5f84-06bc-6546-b485-2cf05d694255',

  getDoctorMedicinesRepresentations: '1ebd5f84-06bc-6546-b485-2cf05d698255',

  assignBatchSaleForceForAdmin: '1ebd5f84-06da-6446-b485-2cf05d694255',

  assignBatchDoctorsAfilliationsForAdmin: '1ebd5f84-06bf-6546-b485-2cf05d698255',

  getLaboratoryData: '1eba6adf-3e07-6f30-bffe-2cf05d694255',

  getMedicalRepresentativesForLaboratory: '1eba7b2a-1cc3-6577-9b91-2cf05d694255',

  getManagersForLaboratory: '1eba7b2a-1cc4-67c4-8f45-2cf05d694255',

  getMedicinesForLaboratory: '1eba6ad5-3e0b-6d95-a98e-2cf05d694255',

  getProgramsForLaboratory: '1eba7b3a-1bc3-61f3-bf10-2cf05d694255',

  getDistributionTypesForLaboratory: '1ebe3ef4-bcd7-6db2-a9f0-fa163e7b67f2',

  getMedicineByKeyWordForLaboratory: '1ebe3fca-5245-6568-8fa9-fa163e7b67f2',

  getDoctorsByKeyWordForLaboratory: '1ebe3fcc-a742-6bd6-b79c-fa163e7b67f2',

  assignBatchMedicineToDoctorForLaboratory: '1ebe35fc-e8ea-69a0-bc03-fa163e7b67f2',
  batchDoctorsAfilliationsForLaboratory: '1ebe35fb-f86f-6ff2-b07a-fa163e7b67f2',
  saleForceAssignForLaboratory: '1ebe35fa-7f56-6f0e-8984-fa163e7b67f2',

  getDoctorsForLaboratory: '1ebb33b4-4e9f-6fb1-b063-2cf05d694255',

  getUnaffiliatedDoctorsForLaboratory: '1ebe4fa4-ba2d-663c-aa4b-fa163e7b67f2',
  affiliateDoctorForLaboratory: '1ebe4fa5-ca80-697a-9522-fa163e7b67f2',

  getItxReportsForAdmin: '1ebe8bc9-5825-60a0-b779-fa163e7b67f2',
  getItxReportDetailForAdmin: '1ebe8bc9-a332-6282-a4df-fa163e7b67f2',

  getMsReportsForAdmin: '1ebe8bc9-da95-6c2e-bbf8-fa163e7b67f2',
  getMsReportDetailForAdmin: '1ebe8bca-2ff0-603e-afbe-fa163e7b67f2',

  getItxReportsForLaboratory: '1ebe8bca-6ff8-6384-8eb4-fa163e7b67f2',
  getItxReportDetailForLaboratory: '1ebe8bca-ac8b-6ec2-ba98-fa163e7b67f2',

  getMsReportsForLaboratory: '1ebe8bca-fb31-63c4-b247-fa163e7b67f2',
  getMsReportDetailForLaboratory: '1ebe8bcb-3211-6402-8014-fa163e7b67f2',

  generateItxReportsForAdmin: '1ebe9ba6-6501-602a-8689-fa163e7b67f2',
  generateItxDetailReportsForAdmin: '1ebe9ba6-cc75-6eea-8dee-fa163e7b67f2',
  generateItxReportsForLaboratory: '1ebe9ba7-34c9-644c-8e07-fa163e7b67f2',
  generateItxDetailReportsForLaboratory: '1ebe9ba7-94d7-6ec4-ac4d-fa163e7b67f2',

  generateMsReportsForAdmin: '1ebe9ba7-f5f1-6c3c-8114-fa163e7b67f2',
  generateMsReportDetailForAdmin: '1ebe9ba8-4e98-6d90-a6b4-fa163e7b67f2',
  generateMsReportsForLaboratory: '1ebe9ba8-aa54-667a-bf9b-fa163e7b67f2',
  generateMsReportDetailForLaboratory: '1ebe9ba9-0ef2-6668-8349-fa163e7b67f2',

  exportDoctorsData: '1ebe9ba9-9ef2-6662-8150-fa163e7b67f2',

  createDoctorsBatch: '1ec26d63-1a47-6ace-83a2-2cf05d694255'
};

/**
 * Contains menu items using objectId as key
 */
export const MENU_ITEMS_SUPER_ADMIN = {
  '1eba6ad0-3e07-65a6-b221-2cf05d694255': {id: 'users', label: 'Usuarios', icon: 'pi pi-user', routerLink: '/auth/users', routeLinkActiveOptions: { exact: true }, order: 0},
  '1eba6ad0-3e08-6b68-9f04-2cf05d694255':
                {id: 'doctors', label: 'Médicos', icon: 'pi pi-user-plus', routerLink: '/auth/doctors', order: 1},
  '1ebb3508-6bff-6faa-bf8b-2cf05d694255':
                {id: 'labs', label: 'Clases Terapéuticas', icon: 'pi pi-sitemap', routerLink: '/auth/atccodes', order: 2},
  '1eba6ad0-3e0a-6152-83be-2cf05d694255':
                {id: 'labs', label: 'Laboratorios', icon: 'pi pi-home', routerLink: '/auth/laboratories', order: 3},
  '1ebac384-f573-6598-a474-2cf05d694255':
                {id: 'labs', label: 'Especialidades Médicas', icon: 'pi pi-heart', routerLink: '/auth/medicalspecialities', order: 4},
  '1ebadfd9-6b18-62c4-ab54-2cf05d694255':
                {id: 'labs', label: 'Órdenes', icon: 'pi pi-table', routerLink: '/auth/orders', order: 5},
  '1ebb4de7-9dc0-625e-a747-2cf05d694255':
                {id: 'manage', label: 'Gestionar asignaciones', icon: 'pi pi-pencil', routerLink: '/auth/manage', order: 6},
  '1ebd5f84-06da-6446-b485-2cf05d694255':
                {id: 'manage', label: 'Asignación Masiva Fuerza de Ventas', icon: 'pi pi-pencil', routerLink: '/auth/saleforce', order: 7},
  '1ebd5f84-06bf-6546-b485-2cf05d698255':
                {id: 'manage', label: 'Asignación Masiva Med-Lab', icon: 'pi pi-pencil', routerLink: '/auth/medlabassign', order: 8},
  'reportsSubMenu': [
      {id: 'itxAdminReport', label: 'Reportes de ITXs', icon: 'pi pi-chart-line', routerLink: '/auth/itx-admin-report', order: 0},
      {id: 'mmAdminReport', label: 'Reportes de MMs', icon: 'pi pi-chart-bar', routerLink: '/auth/sample-admin-report', order: 1},
      {id: 'itxLogReport', label: 'Historial de ITXs', icon: 'pi pi-chart-bar', routerLink: '/auth/itx-admin-log', order: 2},
      {id: 'medicalLogReport', label: 'Historial de Médicos', icon: 'pi pi-chart-bar', routerLink: '/auth/medical-admin-log', order: 3},
      {id: 'inventoryReport', label: 'Reporte de Inventario', icon: 'pi pi-chart-line', routerLink: '/auth/inventory-report', order: 4},
      {id: 'medicalActivityAnalysisReport', label: 'Reporte de Análisis de Actividad Médica', icon: 'pi pi-chart-line', routerLink: '/auth/medical-activity-analysis-report', order: 5},
      // {id: 'questLogsReport', label: 'Reporte de Quest', icon: 'pi pi-chart-line', routerLink: '/auth/quest-logs-report', order: 6}
  ]
}

export const MENU_KEYS = {
  reportsSubMenu: 'reportsSubMenu'
}

//TODO Add items menu for diferent admins
export const MENU_ITEMS_ORDERS_ADMIN = {
  '1ebadfd9-6b18-62c4-ab54-2cf05d694255':
                {id: 'labs', label: 'Órdenes', icon: 'pi pi-table', routerLink: '/auth/orders', order: 0},
}

export const MENU_ITEMS_DOCTORS_ADMIN = {
  '1eba6ad0-3e08-6b68-9f04-2cf05d694255':
                {id: 'doctors', label: 'Medicos', icon: 'pi pi-user-plus', routerLink: '/auth/doctors', order: 0},
  '1ebd5f84-06bf-6544-b485-2cf05d698255':
                {id: 'labs', label: 'Órdenes', icon: 'pi pi-table', routerLink: '/auth/samples-orders', order: 1},
}

export const MENU_ITEMS_JUNIOR_ADMIN = {
  '1eba6ad0-3e08-6b68-9f04-2cf05d694255':
                {id: 'doctors', label: 'Medicos', icon: 'pi pi-user-plus', routerLink: '/auth/doctors', order: 0},
  '1ebd5f84-06bf-6544-b485-2cf05d698255':
                {id: 'labs', label: 'Órdenes', icon: 'pi pi-table', routerLink: '/auth/samples-orders', order: 1},
  '1ebadfd9-6b18-62c4-ab54-2cf05d694255':
                {id: 'labs', label: 'Órdenes', icon: 'pi pi-table', routerLink: '/auth/orders', order: 2},
  '1ebb4de7-9dc0-625e-a747-2cf05d694255':
                {id: 'manage', label: 'Gestionar asignaciones', icon: 'pi pi-pencil', routerLink: '/auth/manage', order: 3},
  '1ebd5f84-06da-6446-b485-2cf05d694255':
                {id: 'manage', label: 'Asignación Masiva Fuerza de Ventas', icon: 'pi pi-pencil', routerLink: '/auth/saleforce', order: 4},
  '1ebd5f84-06bf-6546-b485-2cf05d698255':
                {id: 'manage', label: 'Asignación Masiva Med-Lab', icon: 'pi pi-pencil', routerLink: '/auth/medlabassign', order: 5},
  'reportsSubMenu': [
      {id: 'itxAdminReport', label: 'Reportes de ITXs', icon: 'pi pi-chart-line', routerLink: '/auth/itx-admin-report', order: 0},
      {id: 'mmAdminReport', label: 'Reportes de MMs', icon: 'pi pi-chart-bar', routerLink: '/auth/sample-admin-report', order: 1},
      {id: 'itxLogReport', label: 'Historial de ITXs', icon: 'pi pi-chart-bar', routerLink: '/auth/itx-admin-log', order: 2},
      {id: 'medicalLogReport', label: 'Historial de Médicos', icon: 'pi pi-chart-bar', routerLink: '/auth/medical-admin-log', order: 3}
  ]
}

export const MENU_ITEMS_LABORATORY_ADMIN = {
  '1eba6adf-3e07-6f30-bffe-2cf05d694255':
                {id: 'lab', label: 'Laboratorio', icon: 'pi pi-home', routerLink: '/auth/labhome', order: 0},
  '1eba7b2a-1cc3-6577-9b91-2cf05d694255':
                {id: 'representatives', label: 'Representantes', icon: 'pi pi-user', routerLink: '/auth/representatives', order: 1},
  '1eba7b2a-1cc4-67c4-8f45-2cf05d694255':
                {id: 'managers', label: 'Gerentes', icon: 'pi pi-user-plus', routerLink: '/auth/managers', order: 2},
  '1eba6ad5-3e0b-6d95-a98e-2cf05d694255':
                {id: 'medicines', label: 'Productos', icon: 'pi pi-calendar-plus', routerLink: '/auth/medicines', order: 3},
  '1ed727f5-c2d4-632a-a533-92f70d155d1d':
                {id: 'studies', label: 'Estudios', icon: 'pi pi-calendar-plus', routerLink: '/auth/studies', order: 4},
  '1eba7b3a-1bc3-61f3-bf10-2cf05d694255':
                {id: 'programs', label: 'Programas', icon: 'pi pi-book', routerLink: '/auth/programs', order: 5},
  '1ebd5f84-06bf-6544-b485-2cf05d698255':
                {id: 'labs', label: 'Órdenes', icon: 'pi pi-table', routerLink: '/auth/samples-orders', order: 6},
  '1ebe3fca-5245-6568-8fa9-fa163e7b67f2':
                {id: 'lab-manage', label: 'Gestionar asignaciones', icon: 'pi pi-pencil', routerLink: '/auth/lab-manage', order: 7},
  '1ebe35fb-f86f-6ff2-b07a-fa163e7b67f2':
                {id: 'manage', label: 'Asignación Masiva Med-Lab', icon: 'pi pi-pencil', routerLink: '/auth/medlabassign-lab', order: 8},
  '1ebe35fa-7f56-6f0e-8984-fa163e7b67f2':
                {id: 'manage', label: 'Asignación Masiva Fuerza de Ventas', icon: 'pi pi-pencil', routerLink: '/auth/saleforce-laboratory', order: 9},
  '1ebb33b4-4e9f-6fb1-b063-2cf05d694255':
                {id: 'associated-doctors', label: 'Médicos asociados', icon: 'pi pi-users', routerLink: '/auth/associated-doctors', order: 10},
  '1ebe4fa4-ba2d-663c-aa4b-fa163e7b67f2':
                {id: 'no-associated-doctors', label: 'Médicos no asociados', icon: 'pi pi-user-minus', routerLink: '/auth/no-associated-doctors', order: 11},
  '1ebe8bca-6ff8-6384-8eb4-fa163e7b67f2':
                {id: 'itxLabReport', label: 'Reportes de ITXs', icon: 'pi pi-chart-line', routerLink: '/auth/itx-lab-report', order: 12},
  '1ebe8bca-fb31-63c4-b247-fa163e7b67f2':
                {id: 'mmLabReport', label: 'Reportes de MMs', icon: 'pi pi-chart-bar', routerLink: '/auth/sample-lab-report', order: 13}
}

export const ADMIN_ROLES = {
  superAdmin: '1ebba5d5-4a4b-6844-bdc0-2cf05d694255',
  ordersAdmin: '1ebba5d5-4a53-6cba-a05a-2cf05d694255',
  juniorAdmin: '1ed9e8b5-b59d-6db8-9114-92f70d155d1c',
  doctorsAdmin: '1ebba5d5-4a53-6f1c-972e-2cf05d694255',
  laboratoryAdmin: '1ebbca27-c2a2-6fd8-8438-2cf05d694255',
  contactAdmin: '1ebf6ecd-6c97-632a-920e-fa163e7b67f2'
}

export const DISTRIBUTION_TYPES = {
  initialTreatment: '1eba2d2b-5de6-6332-a2d4-2cf05d694255',
  medicalSample: '1eba2d2c-0d87-6b88-add3-2cf05d694255',
  both: '1eba2d2c-1957-6576-b84e-2cf05d694255'
}

export enum OrderTreatmentStatus {
  New = 1,
  Accepted,
  NoAccepted,
  OrderRequested,
  OrderNoRequested,
  OrderDispatched
}

export const MONTHS_AGO = {
  Default: 3
}

export const OrderStatuses = {
  TreatmentStarted: '1ebac56e-23fb-6768-9926-2cf05d694255',
  AcceptedByPatient: '1ebac56e-2427-6cc8-afc3-2cf05d694255',
  NoAcceptedByPatient: '1ebac56e-2427-6f84-90d5-2cf05d694255',
  OrderRequested: '1ebac56e-2428-6128-8450-2cf05d694255',        //orderRealized
  OrderNoRequested: '1ebac56e-2428-629a-be3c-2cf05d694255',      //notOrderForDifficulty
  OrderDispatched: '1ebac56e-2428-6466-93f5-2cf05d694255',       //receivedByPatient
  Expired: '1ec26d63-7beb-68a2-bd3c-2cf05d694255'
}

export const MedicalSampleStatuses = {
  Sent: '1ebb3fe9-712d-65f4-b61f-2cf05d694255',
  Attended: '1ebb3fe9-714e-6a60-9e70-2cf05d694255',
  Expired: '1ebb3fe9-714e-6d30-895c-2cf05d694255'
}

export const CouponTypes = {
  DummyCoupon: '1ec96670-adc4-6ec0-bd4a-2cf05d694255',
  SimpleCoupon: '1ec966a5-6a06-6e2e-9360-2cf05d694255',
  PspCoupon: '1ec966a5-f1da-60b2-b551-2cf05d694255',
  StudyCoupon: '1ed6c519-0f14-67c0-b7f4-92f70d155d1d',
}

let ENV = 'prod'; // local | prod | qa
let isAzure = true;

export const LABORATORIES = {
  lilly: {
    objectId: ENV === 'prod'  ? '1ed45864-023d-6e44-8c4d-374aaf92bde3' :
              ENV === 'qa'    ? '1ed83039-8a97-61e0-9613-19a9e808b957' :
              ENV === 'local' ? '1ed74e1c-9ee5-62f0-95b3-92f70d155d1d' :
              null
  },
  lillyQuest: {
    objectId: ENV === 'prod'  ? '1ed45864-023d-6e44-8c4d-374aaf92bde3' :
              ENV === 'qa'    ? '1ed83039-8a97-61e0-9613-19a9e808b957' :
              ENV === 'local' ? '1ed74e1c-9ee5-62f0-95b3-92f70d155d1d' :
              null
  },
  iknesoft: {
    objectId: ENV === 'prod'  ? '1ecdde49-dcf1-6676-93db-0b46bded43ef' :
              ENV === 'qa'    ? '1ec4ee87-4a28-6cfa-82de-fa163e7b67f2' :
              ENV === 'local' ? '1edbdf2f-ea8f-6df6-b621-92f70d155d1c' :
              null
  },
  astrazeneca: {
    objectId: ENV === 'prod'  ? '1ecddfd0-e3a3-6e46-ba99-79f202f7325f' :
              ENV === 'qa'    ? '1ebbf287-cdc6-6ba2-8ff5-fa163e7b67f2' :
              ENV === 'local' ? '1ecddfd0-e3a3-6e46-ba99-79f202f7325f' :
              null
  },
  novartis: {
    objectId: ENV === 'prod'  ? '1ee77f66-a76a-6dfe-a22c-5fdd61f4fecd' :
              ENV === 'qa'    ? '1ee78ff9-fe82-658a-bcc6-92f70d155d1d' :
              ENV === 'local' ? '1ee78ff9-fe82-658a-bcc6-92f70d155d1d' :
              null
  },
  novoNordisk: {
    objectId: ENV === 'prod'  ? '1eeb3fdc-25e1-6f1e-baf4-97fc5de33b0b' :
              ENV === 'qa'    ? '1ee78ff9-fe82-658a-bcc6-92f70d155d1d' :
              ENV === 'local' ? '1eeb64f8-99b6-6354-9be9-92f70d155d1d' :
              null
  },
  coi: {
    objectId: ENV === 'prod'  ? '1ef21e97-29c5-63ec-9c7c-fdd1b150a562' :
              ENV === 'qa'    ? '5d3ce50c-d95c-42e9-867b-6917b8dd7c24' :
              ENV === 'local' ? '5d3ce50c-d95c-42e9-867b-6917b8dd7c24' :
              null
  },
  qTest: {
    objectId: ENV === 'prod'  ? '1ef21ea4-6d5e-61aa-91ad-ffb0a911739b' :
              ENV === 'qa'    ? '7be5385f-7d58-4e82-9985-7139108ef7f1' :
              ENV === 'local' ? '7be5385f-7d58-4e82-9985-7139108ef7f1' :
              null
  }
}

export const BACKEND_URLS = {

  dotNet: ENV === 'prod'  ?
            isAzure
              ? 'https://iniciotxservice.azurewebsites.net/api'
              : 'https://mtwt6z469a.execute-api.us-east-1.amazonaws.com' :
          ENV === 'qa'    ?
            isAzure
              ? 'https://iniciotxdevelop.azurewebsites.net/api'
              : 'https://hvcqr3uo58.execute-api.us-east-1.amazonaws.com' :
          ENV === 'local' ? 'http://localhost:8081' :
          null,

  php:    ENV === 'prod'  ? 'https://mtwt6z469a.execute-api.us-east-1.amazonaws.com' :
          ENV === 'qa'    ? 'https://hvcqr3uo58.execute-api.us-east-1.amazonaws.com' :
          ENV === 'local' ? 'http://localhost:8081' :
          null
}

export const LANDING_MEDICINES = {
  emgality: '1ed5f80e-1fb0-677e-aed0-d9c0a5aa8764',
  olumiant: '1ed5c730-d622-6d2c-b4a0-8d5290bb4fac',
  taltz: '1ed5f821-7f79-61c0-bb4b-5d29fb084075',
  cialis: '1ed458a3-f3ca-6cdc-be87-83693d06bd80',
  humalog: '1ed458b9-7a8d-6f20-922c-e337989e4ecf',
  abasaglar: '1ed45892-6305-629e-a9ca-6bdd5fe4e5ca',
  saxenda: '08cba5e8-b7c4-4c31-aed7-3d44249c2831',
  cosentyx: ENV === 'prod'
    ? '1ee77f9a-041d-6e60-9d30-e514c2060363'
    : '1ee79ba3-70ff-6c16-ad64-92f70d155d1d',
  kisqali: ENV === 'prod'
    ? '74d8e242-d87f-4bb2-b610-6371c9ecd614'
    : '1eed745e-1dee-6fb0-9457-3b68f26facfc',
  jakavi: [
    '30a11d7d-dfd0-47d9-88ca-f6acbd4764dd',
    '3b0080d2-d88d-4579-a918-42b96a06c6c0',
    'cd9520e2-aaf8-4264-8e9e-e6c5d04052fb',
    '1be13b83-c487-4d0c-931f-93827164b134'
  ],
  bonspri: '144d25e6-4cf3-4737-9f4a-62a1b001209b',
  scemblix: [
    'f9914e49-bdf1-492a-b95d-613110c17a86',
    'ec85b720-36b0-4150-855d-779a84fd4800',
  ],
  verzenio: [
    '269e1ea1-c7eb-4ccc-944f-67c85f27e4aa',
    'c40d1d99-a786-4aa4-897c-066ce7296b40',
  ]
}

export const QUEST_REDIRECT_PREFIXES = {
  [LABORATORIES.lillyQuest.objectId]: 'quest',
  [LABORATORIES.coi.objectId]: 'coi',
  [LABORATORIES.qTest.objectId]: 'q-test'
}
